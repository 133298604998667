<template>
  <div class="position-relative">
    <div>
      <div class="bg-light">
        <div class="container py-5">
          <div class="row h-100 align-items-center py-5">
            <div class="col-12 col-lg-12 col-md-12 col-sm-12 mt-5">
              <h3 class="display-4 font-weight-bold text-white mt-5">Blogs</h3>
              <p class="lead text-white mb-5">
              Find out more about the latest information from us
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-around" v-if="loading">
        <loader-component></loader-component>
      </div>
      <div class="mt-5 py-5"  v-if="!loading">
        <div class="container">
          <h4 class="text-center font-weight-bold mb-5">Our Latest News</h4>
          <div class="row">
            <div class="col-12 col-md-4" v-for="(blog, p) in data" :key="p">
              <div class="card shadow">
                <img
                  class="card-img-top-blog"
                  style="height: 270px; object-fit: cover"
                  :src="blog.file_endpoint"
                  alt="Card image cap"
                />
                <div class="card-body">
                  <h5 class="card-title text-left">{{ blog.title }}</h5>
                  <p class="card-text text-left">
                    {{ blog.body }}
                  </p>
                  <div class="d-flex justify-content-end">
                    <a @click.prevent="toDetail(blog.slug)" class="btn-link"
                      >Read More ...
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoaderComponent from "../../components/Loader.vue";
export default {
  name: "Blog",
  components: { LoaderComponent },
  data() {
    return {
      loading: true,
      data: [],
    };
  },

  mounted() {
    this.getAllBlogs();
  },

  methods: {
    getAllBlogs() {
      this.loading = true;
      const endpoint = "blog";
      this.$api.get(endpoint, (status, data, message) => {
        if (status === 200) {
          this.loading = false;
          this.data = data.blog;
          console.log(this.data);
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },

    toDetail(slug) {
      this.$router.push("/detail-blog/" + slug);
    },
  },
};
</script>
